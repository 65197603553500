




















import { Component, Vue } from 'vue-property-decorator'
import { vxm } from '@/store/store.vuex'
import FullscreenPhotoViewer from '@/components/FullscreenPhotoViewer.vue'
import fb from 'firebase/app'
import Photo = fb.firestore.DocumentData

@Component({
  components: { FullscreenPhotoViewer }
})
export default class Gallery extends Vue {
  mounted() {
    this.$store.dispatch('firestore/getGalleryPhotos')
  }

  displayFullscreenImage(photo: Photo) {
    this.$store.commit('app/setSelectedPhoto', photo)
    this.$store.commit('app/toggleFullscreenImage', true)
  }

  get isDisplayingFullscreenImage() {
    return vxm.app.isDisplayingFullscreenImage
  }

  get galleryPhotos() {
    return vxm.firestore.galleryPhotos
  }
}
