
























import { Component, Vue, PropSync, Watch } from 'vue-property-decorator'
import fb from 'firebase/app'
import Photo = fb.firestore.DocumentData
import { vxm } from '@/store/store.vuex'
@Component
export default class FullscreenPhotoViewer extends Vue {
  @PropSync('component', { type: String }) currentComponent!: string

  photos: Photo[] = []

  mounted() {
    this.photos = []
    this.updateGalleryPhotos()
    this.updateAlbumsPhotos()
    document.addEventListener('keydown', (event: KeyboardEvent) => {
      switch (event.key) {
        case 'Left': // IE/Edge specific value
        case 'ArrowLeft':
          this.changeLeftPhoto()
          break

        case 'Right': // IE/Edge specific value
        case 'ArrowRight':
          this.changeRightPhoto()
          break

        case 'Esc': // IE/Edge specific value
        case 'Escape':
          this.togglePreview()
          break
        default:
          return
      }
    })
  }

  onImgClick($event: MouseEvent) {
    const median = window.innerWidth / 2
    if ($event.clientX > median) {
      this.changeRightPhoto()
    } else {
      this.changeLeftPhoto()
    }
  }

  changeLeftPhoto() {
    let index = 0
    const currentIndex = this.photos.indexOf(this.photo)
    if (currentIndex > 0) {
      index = currentIndex - 1
      const fullscreenImg = document.getElementById('fullscreenImg')
      fullscreenImg?.classList.add('fade')
      setTimeout(() => {
        this.$store.commit('app/setSelectedPhoto', this.photos[index])
        fullscreenImg?.classList.remove('fade')
      }, 150)
    }
  }
  changeRightPhoto() {
    let index = 0
    const currentIndex = this.photos.indexOf(this.photo)
    const fullscreenImg = document.getElementById('fullscreenImg')
    if (currentIndex !== -1 && currentIndex < this.photos.length - 1) {
      index = currentIndex + 1
      fullscreenImg?.classList.add('fade')
      setTimeout(() => {
        this.$store.commit('app/setSelectedPhoto', this.photos[index])
        fullscreenImg?.classList.remove('fade')
      }, 150)
    }
  }

  togglePreview() {
    this.$store.commit('app/toggleFullscreenImage', false)
    this.$store.commit('app/setSelectedPhoto', {})
  }

  get photo() {
    return vxm.app.selectedFullscreenImage
  }

  @Watch('galleryPhotos')
  updateGalleryPhotos() {
    if (this.currentComponent === 'gallery') this.photos = this.galleryPhotos
  }
  get galleryPhotos() {
    return vxm.firestore.galleryPhotos
  }

  @Watch('albumPhotos')
  updateAlbumsPhotos() {
    if (this.currentComponent === 'albums') this.photos = this.albumPhotos
  }
  get albumPhotos() {
    return vxm.firestore.albumPhotos
  }
}
